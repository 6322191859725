import React, { useState, useEffect, useRef } from 'react'
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Button, ButtonGroup, Col, Container, Row } from 'react-bootstrap'
import { Stack } from '@mui/material';
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { BiSolidSquare, BiSolidGridAlt, BiSolidGrid } from "react-icons/bi";
import { FaTimes } from "react-icons/fa";

import { IconButtonComponent } from 'Shared/Components/Icon';
import LoadingScreen from 'Shared/Components/LoadingScreen';
import { cloneJson } from 'Shared/utils';
import "./style.css"

const DragItem = ({ item }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "item",
    item: item,
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  return (
    <div ref={drag} style={{padding: "5px", backgroundColor: "white",  overflow: "hidden", whiteSpace: "nowrap"}} >
      {item[1]}
    </div>
  );
}

const Player = ({ url }) => {
  return (<>
    {url ? (
      <iframe src={url} style={{"width": "100%", "height": "100%", "pointerEvents": "none"}} />
    ) : (
      <div style={{"width": "100%", height: "100%", backgroundColor: "black"}}></div>
    )}
  </>)
}

const PlayerWindow = ({ setArray, item }) => {
  const dropTarget = useRef();

  const [{ isOver }, drop] = useDrop(() => ({
    accept: "item",
    drop: (i) => setArray(i),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));
  
  return (
    <div className={isOver ? "windowContainerIsOver" : "windowContainer"} ref={drop(dropTarget)} style={{"width": "100%", "height": "100%"}}>
      <Stack className='streamheader' justifyContent="space-between" direction="row">
        <div className='streamTitle'>{item[1] ?? '\u00a0'}</div>
        {(item.length != 0) && 
          <FaTimes size={20} style={{"color": "white", "marginTop": "auto", "marginBottom": "auto"}} onClick={() => setArray([])}/>
        }
      </Stack>
    
      <div style={{"flexGrow": "1"}}>
        <Player url={item[0]}/>
      </div>
    </div>
  )
}

const Live = () => {
  const routeParam = useParams()
  const { t } = useTranslation()

  const [grid, setGrid] = useState()
  const [streams, setStreams] = useState([])
  const [streamId, setStreamId] = useState([])

  const [loading, setLoading] = useState(true);

  const stateRef = useRef({});

  const loadStreams = async () => {
    const res = await fetch(`https://streaming.simplesymmetry-tech.com/playback/streams?project=${routeParam.project}`, {
      method: "GET",
      headers: {
        "Authorization": localStorage.getItem("access_token")
      }
    })

    if (res.status !== 200) {
      alert("ERROR")
      return
    }

    setStreams(await res.json())
  }

  useEffect(() => { 
    const init = async () => {
      await loadStreams()
      await initArray(2)

      setLoading(false)
    }
    
    init()
  }, [])

  const initArray = (grid) => {
    const array = Array.apply(null, {length: grid}).map(i => Array.apply(null, {length: grid}).map(i => []))
    
    setStreamId(array)
    stateRef.current.streamID = array

    setGrid(grid.toString())
  }

  const setArray = (row, col, item) => {
    const temp = cloneJson(stateRef.current.streamID)
    temp[row][col] = item.length ? [`https://streaming.simplesymmetry-tech.com/live/${item[0]}/`, item[1]] : []

    stateRef.current.streamID = temp
    setStreamId(temp)
  }

  const changeGrid = (grid) => {
    initArray(grid)
  }

  return (<>
    <div className="mainContainer" style={{ height: "100%", padding: "10px" }}>
      <DndProvider backend={HTML5Backend}>
        <Container fluid className="mainContainer">
          <Row>
            <Col style={{ borderTop: "1px solid black", borderLeft: "1px solid black", borderRight: "1px solid black" }}>
              <Stack justifyContent="end" direction="row" spacing={2}>
                <Stack direction="row" spacing={2}>
                  <IconButtonComponent title="" onClick={() => changeGrid(1)}>
                    <BiSolidSquare size={20}/>
                  </IconButtonComponent>

                  <IconButtonComponent title="" onClick={() => changeGrid(2)}>
                    <BiSolidGridAlt size={20}/>
                  </IconButtonComponent>
                  
                  <IconButtonComponent title="" onClick={() => changeGrid(4)}>
                    <BiSolidGrid size={20}/>  
                  </IconButtonComponent>
                </Stack>
              </Stack>
            </Col>
          </Row>
          
          <Row style={{flexGrow: 1, overflow: "hidden"}}>
            <Col xs={3} style={{ height: "100%", overflow: "auto", paddingLeft: 0, paddingRight: 0, borderTop: "1px solid black", borderLeft: "1px solid black", borderBottom: "1px solid black" }}>
              <Stack direction="column">
                {streams.map((i) => <>
                  <DragItem item={[i.route, i.stream_name]} />
                </>)}
              </Stack>
            </Col>

            <Col xs={9} style={{ height: "100%", paddingLeft: 0, paddingRight: 0}}>
              {streamId.map((i, row) => <>
                <div className={`h-${100/grid}`} style={{ height: "100%", display: "flex", flexFlow: "row", overflow: "hidden" }}>
                  {i.map((j, col) => <>
                    <div className={`w-${100/grid}`} style={{ height: "100%", overflow: "hidden" }}>
                      <PlayerWindow setArray={(i) => setArray(row, col, i)} item={j}/>
                    </div>
                  </>)}
                </div>
              </>)}
            </Col>
          </Row>
        </Container>
      </DndProvider>

      <LoadingScreen isOpen={loading} text={t("general_loading")}/>
    </div>
  </>)
}

export default Live