import React, { useRef, useState, useEffect, useLayoutEffect, useImperativeHandle, forwardRef } from "react";
import { Map, View } from "ol";
import { XYZ, Vector as VectorSource } from "ol/source";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer";
import {  Style, Stroke } from "ol/style";
import moment from 'moment'
import { FaPlay, FaStop } from "react-icons/fa6";
import { HiOutlineDocumentReport } from "react-icons/hi";
import { Stack } from '@mui/material';

import { showError } from "Shared/Components/NotifyToast";
import { IconButtonComponent } from "Shared/Components/Icon";

import { getIconStyle, getLabelStyle, setLayerSource, mapViewConfiguration } from './utils'
import './style.css'

const MapReplay = forwardRef(({ start_dt, end_dt, exportReport }, ref) => {
  const mapRef = useRef()

  const [time, setTime] = useState(null)
  const replayRef = useRef({})

  useEffect(() => {
    return () => {
      if (replayRef.current.loop) {
        clearInterval(replayRef.current.loop);
      }
    }
  }, [])

  useLayoutEffect(() => {
    const workerLayer = new VectorLayer({
      source: new VectorSource({}),
      style: function (feature) { 
        return [
          getIconStyle("worker_normal", 30, 1 ),
          getLabelStyle(moment(feature.get("datetime")).format("HH:mm"), "black", 1)
      ]}
    })

    workerLayer.set('name', 'workerLayer')

    const pathLayer = new VectorLayer({
      source: new VectorSource({}),
      style: new Style({
        stroke: new Stroke({
          width: 3, color: "blue"
        })
      })
    })

    pathLayer.set('name', 'pathLayer')

    const map = new Map({
      target: "map",
      layers: [
        new TileLayer({
          source: new XYZ({
            url: 'https://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}'
          })
        }),
        pathLayer, workerLayer
      ],
      view: new View({
        center: mapViewConfiguration["center"],
        extent: mapViewConfiguration["extent"],
        zoom: 0,
        showFullExtent: true
      })
    })

    mapRef.current = {
      "map": map,
      "workerLayer": workerLayer,
      "pathLayer": pathLayer
    }
  }, [])

  useImperativeHandle(ref, () => ({
    updateData: (points, lines) => {
      mapRef.current.points = points
      mapRef.current.lines = lines

      setLayerSource(mapRef.current.workerLayer, points)
      setLayerSource(mapRef.current.pathLayer, lines)
    }
  }))

  const update = () => {
    const updateFeature = (feature, line_coor) => {
      setLayerSource(mapRef.current.workerLayer, {
        "type": "FeatureCollection",
        "features": [feature]
      })

      setLayerSource(mapRef.current.pathLayer, {
        "type": "FeatureCollection",
        "features": [{
          "type": "Feature",
          "geometry": {
            "type": "LineString",
            "coordinates": line_coor
          }
        }]
      })
    }

    const t = moment(replayRef.current.time).add(60, "seconds").toDate()

    setTime(t)
    replayRef.current.time = t

    const points = mapRef.current.points
    const lines = mapRef.current.lines

    if (replayRef.current.index == null) {
      const first_feature = points["features"][0]

      if (t > moment(first_feature["properties"]["datetime"])) {
        replayRef.current.index = 0

        updateFeature(first_feature, [])
      }
    }
    else {
      if (replayRef.current.time >= end_dt) {
        end()
      }
      else {
        if (replayRef.current.index < points["features"].length-1) {
          const next_feature = points["features"][replayRef.current.index + 1]

          if (t > moment(next_feature["properties"]["datetime"])) {
            replayRef.current.index += 1

            updateFeature(next_feature, lines["features"][0]["geometry"]["coordinates"].slice(0, replayRef.current.index+1))
          }
        }
      }
    }
  }

  const start = () => {
    if (!mapRef.current.points || !mapRef.current.lines) {
      showError("No time period selected")
      return
    }
      
    mapRef.current.workerLayer.getSource().clear()
    mapRef.current.pathLayer.getSource().clear()

    setTime(start_dt)

    replayRef.current = {
      "time": start_dt,
      "index": null,
      "loop": setInterval(function() { update() }, 500)
    }
  }

  const end = () => {
    clearInterval(replayRef.current.loop)

    setLayerSource(mapRef.current.workerLayer, mapRef.current.points)
    setLayerSource(mapRef.current.pathLayer, mapRef.current.lines)

    setTime()
    replayRef.current = {}
  }

  return (
    <div style={{ height: "100%", width: "100%", display: "flex", flexDirection: "column" }}>
      <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
        <div style={{ width: "150px" }}>{time && moment(time).format("YYYY-MM-DD HH:mm:00")}</div>

        <Stack direction="row" spacing={2}>
          {replayRef.current.time ? (
            <IconButtonComponent size="small" title="Stop">
              <FaStop onClick={() => end()}/>
            </IconButtonComponent>
          ) : (
            <IconButtonComponent size="small" title="Start">
              <FaPlay onClick={() => start()}/>
            </IconButtonComponent>
          )}

          <IconButtonComponent size="small" title="Report">
            <HiOutlineDocumentReport onClick={() => exportReport("location")}/>
          </IconButtonComponent>
        </Stack>
      </div>

      <div style={{ width: "100%", flexGrow: 1 }} id="map"></div>
      <div style={{"display": "none"}}>
        <div id="popup" className="popup"></div>
      </div>
    </div>
  )
})

export default MapReplay
