import React, { useState, useEffect } from 'react'
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import moment from 'moment'
import { saveAs } from 'file-saver'
import { Button, ButtonGroup, Col, Container, Row } from 'react-bootstrap'

import TimeRangePicker from 'Shared/Components/TimeRangePicker/TimeRangePicker'
import DatePickerComponent from 'Shared/Components/DatePickerComponent'
import { DropdownComponent } from 'Shared/Components/DropdownComponent'
import LoadingScreen from 'Shared/Components/LoadingScreen';

const Playback = () => {
  const routeParam = useParams()
  const { t } = useTranslation()

  const [streams, setStreams] = useState([])

  const [selected, setSelected] = useState()
  const [date, setDate] = useState()

  const [error, setError] = useState(true)
  const [selectedInterval, setSelectedInterval] = useState()
  const [timelineInterval, setTimelineInterval] = useState()
  const [disabledIntervals, setDisabledIntervals] = useState()

  const [playUrl, setPlayUrl] = useState()

  const [loading, setLoading] = useState(true);

  const loadStreams = async () => {
    const res = await fetch(`https://streaming.simplesymmetry-tech.com/playback/streams?project=${routeParam.project}&recording=1`, {
      method: "GET"
    })

    if (res.status !== 200) {
      alert("ERROR")
      return
    }

    setStreams(await res.json())
  }

  useEffect(() => { 
    const init = async () => {
      await loadStreams()

      setLoading(false)
    }
    
    init()
  }, [])

  const onChangeCallback = (e) => {
    console.log(e)

    setError(e.error)
    e.interval && setSelectedInterval(e.interval)
  }

  const loadData = async (d, p) => {
    if (d == null || p == null) {
      return
    }

    const res = await fetch(`https://streaming.simplesymmetry-tech.com/playback/playback/list/${p}/${moment(d).format("YYYY-MM-DD")}`, {
      method: "GET"
    })

    const body = await res.json()

    setDisabledIntervals(
      body.map((i) => ({
        'start': Date.parse(i["start"]),
        'end': Date.parse(i["end"])
      }))
    )

    setSelectedInterval([moment(d).startOf('day').toDate(), moment(d).add(1, "days").startOf('day').toDate()])
    setTimelineInterval([moment(d).startOf('day').toDate(), moment(d).add(1, "days").startOf('day').toDate()])
  }

  const play = () => {
    if (error) {
      alert(`Error: Invalid Range`)
      return
    }

    const url = `https://streaming.simplesymmetry-tech.com/playback/playback/get/${selected}/player?start_dt=${moment(selectedInterval[0]).format("YYYY-MM-DD_HH-mm-SS")}&end_dt=${moment(selectedInterval[1]).format("YYYY-MM-DD_HH-mm-SS")}`
    setPlayUrl(url)
  }

  const download = async () => {
    if (error) {
      alert(`Error: Invalid Range`)
      return
    }
    else if (moment(selectedInterval[1]).diff(moment(selectedInterval[0]), 'minutes', true) > 240) {
      alert(`Error: Maximum download interval: 4 hour`)
      return
    }

    const res = await fetch(`https://streaming.simplesymmetry-tech.com/playback/playback/download/${selected}?start_dt=${moment(selectedInterval[0]).format("YYYY-MM-DD_HH-mm-SS")}&end_dt=${moment(selectedInterval[1]).format("YYYY-MM-DD_HH-mm-SS")}`, {
      method: "GET"
    })

    const blob = await res.blob()

    saveAs(blob, `playback.mp4`)
  }

  const updateDate = (d) => {
    setDate(d)

    loadData(d, selected)
  }

  const updatePath = (p) => {
    setSelected(p)

    loadData(date, p)
  }

  return (<>
    <Container fluid className="mainContainer">
      <Row>
        <Col>
          <DropdownComponent value={selected} items={streams.map((i) => [i.route, i.stream_name])} onChange={(value) => updatePath(value)}/>
        </Col>

        <Col>
          <DatePickerComponent date={date} type="day" max={new Date()} onChange = {(d) => updateDate(d)} />
        </Col>

        <Col>
          {(selected != null && date != null) && <>
            <ButtonGroup style={{width: "100%"}}>
              {(playUrl == null) ? (<>
                <Button variant="primary" style={{width: "50%"}} onClick={() => play()}>Play</Button>
                <Button variant="success" style={{width: "50%"}} onClick={() => download()}>Download</Button>
              </>) : (
                <Button variant="danger" style={{width: "100%"}} onClick={() => setPlayUrl()}>Stop</Button>
              )}
            </ButtonGroup>
          </>}
        </Col>
      </Row>

      {(selected != null && selectedInterval != null && disabledIntervals != null && timelineInterval != null) && <>
        <Row>
          <Col>
            <TimeRangePicker
              error={error}
              step={60000}
              ticksNumber={120}
              selectedInterval={selectedInterval}
              timelineInterval={timelineInterval}
              disabledIntervals={disabledIntervals}
              onChangeCallback={onChangeCallback}
              setTimelineInterval={setTimelineInterval}
              min={moment(date).startOf('day').toDate()}
              max={moment(date).add(1, "days").startOf('day').toDate()}
              showNow
            />

            <div style={{"padding-left": "30px"}}>{`${moment(selectedInterval[0]).format("YYYY-MM-DD HH:mm:SS")} ~ ${moment(selectedInterval[1]).format("YYYY-MM-DD HH:mm:SS")}`}</div>
          </Col>
        </Row>

        <Row style={{ flexGrow: 1 }}>
          <Col style={{ height: "100%" }}>
            {(playUrl) &&
              <div style={{ width: "100%", height: "100%", flexGrow: 1, overflowX: "hidden", overflowY: "hidden" }}>
                <iframe src={playUrl} style={{"width": "100%", "height": "100%"}}/>
              </div>
            }
          </Col>
        </Row>
      </>}
    </Container>

    <LoadingScreen isOpen={loading} text={t("general_loading")}/>
  </>);
}

export default Playback