import {  Icon, Fill, Text, Style } from "ol/style";
import { GeoJSON } from "ol/format";

import { webUrl } from "Shared/utils";

const mapViewConfiguration = {
  center: [12702887, 2555404],
  extent: [12667814, 2529395, 12737960, 2581413]
}

const getIconStyle = (icon, size, zIndex) => {
  return new Style({
    image: new Icon({
      src: `${webUrl}/icons/${icon}.png`,
      width: size,
      height: size
    }),
    zIndex: zIndex
  })
}

const getLabelStyle = (label, color, zIndex) => {
  return new Style({
    text: new Text({
      font: '14px sans-serif',
      text: label,
      fill: new Fill({
        color: color
      }),
      offsetY: -30
    }),
    zIndex: zIndex
  })
}

const setLayerSource = (layer, data) => {
  layer.getSource().clear()
  layer.getSource().addFeatures(new GeoJSON({ featureProjection:"EPSG:3857" }).readFeatures(data))
}

export { getIconStyle, getLabelStyle, setLayerSource, mapViewConfiguration }
