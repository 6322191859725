import React, { useState, useEffect } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { SnackbarProvider, useSnackbar } from 'notistack';

import Main from 'Pages/Main/Main';
import Login from 'Pages/Login/Login';

import Smartwatch from 'Pages/Smartwatch/Smartwatch';
import SmartwatchDetail from 'Pages/Smartwatch/SmartwatchDetail';
import Live from 'Pages/Live/Live';
import Playback from 'Pages/Playback/Playback';
import Alert from 'Pages/Alert/Alert';

import { NotifyToast } from 'Shared/Components/NotifyToast'
import WarningToast from 'Shared/Components/WarningToast';
import useFetchData from 'Shared/Hooks/useFetchData';
import { apiUrl } from 'Shared/utils';

function App() {
  const { send } = useFetchData()

  const [start, setStart] = useState()
  const [loggedIn, setLoggedIn] = useState()

  const refresh = async () => {
    const body = await send({
      url: `${apiUrl}/authentication/refresh`,
      method: "POST",
      body: JSON.stringify({
        "refresh_token": localStorage.getItem("refresh_token")
      }),
      returnType: "json"
    })

    if (body == null) {
      localStorage.removeItem("access_token")
      localStorage.removeItem("refresh_token")
      setLoggedIn(false)

      return false
    }

    localStorage.setItem("access_token", body["access_token"])
    localStorage.setItem("refresh_token", body["refresh_token"])
    setRefreshTimeout(body["expires_in"])

    return true
  }

  const setRefreshTimeout = async (expires_in) => {
    setTimeout(() => { refresh() }, 1000*(expires_in - 120))
  }

  const loginCallback = (expires_in) => {
    setLoggedIn(true)
    setRefreshTimeout(expires_in)
  }

  const logoutCallback = () => {
    localStorage.removeItem("access_token")
    localStorage.removeItem("refresh_token")
    setLoggedIn(false)
  }

  useEffect(() => {
    const init = async () => {
      if (localStorage.getItem("access_token") == null || localStorage.getItem("refresh_token") == null) {
        setLoggedIn(false)
      }
      else {
        const success = await refresh()

        if (success) {
          setLoggedIn(true)
        }
      }
  
      setStart(true)
    }
    
    init()
  }, [])

  if (!start) return <div></div>

  if (!loggedIn) return <Login loginCallback={loginCallback}/>

  return (<>
    <SnackbarProvider
      maxSnack={5} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      Components={{ warningToast: WarningToast }}
    >
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <Routes>
          <Route path='/:project?/*' element={<Main logoutCallback={logoutCallback}/>}>
            <Route path="alert" element={<Alert />} />
            <Route exact path="smartwatch" element={<Smartwatch />} />
            <Route path="smartwatch/:device_IMEI" element={<SmartwatchDetail />} />
            <Route path="live" element={<Live />} />
            <Route path="playback" element={<Playback />} />
          </Route>
        </Routes>
      </BrowserRouter>

      <div>{<NotifyToast/>}</div>
    </SnackbarProvider>
  </>);
}

export default App;
