import React, { useEffect, useRef, useState } from 'react'
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Container, Col, Row } from 'react-bootstrap';
import { Paper } from '@mui/material';

import DataTable from 'Shared/Components/DataTable'
import LoadingScreen from 'Shared/Components/LoadingScreen';
import useFetchData from 'Shared/Hooks/useFetchData';
import { apiUrl, itemsPerPage } from 'Shared/utils';

const Alert = () => {
  const { send } = useFetchData()
  const { t } = useTranslation()

  const [data, setData] = useState({})
  const [loading, setLoading] = useState(true);

  const tableRef = useRef()

  const loadData = async () => {
    tableRef.current.setLoading(true)

    const body = await send({
      url: `${apiUrl}/alerts/alert_history`,
      params: {
        offset: (tableRef.current.getState()["page"]-1)*itemsPerPage,
        limit: itemsPerPage
      },
      method: "GET",
      returnType: "json"
    })

    if (body != null) {
      setData({
        "data": body["data"].map((i) => ({
          "device": i["device_name"],
          "datetime": i["datetime"],
          "event": `${i["type_name"]} (${i["event_name"]})`
        })),
        "count": Math.ceil(body["count"]/itemsPerPage)
      })

      tableRef.current.setLoading(false)
    }
  }

  useEffect(() => {
    const init = async () => {
      await loadData()

      setLoading(false)
    }
    
    init()
  }, [])

  return (<>
    <div className="mainContainer" style={{ height: "100%", padding: "10px" }}>
      <Container fluid style={{ height: "100%" }}>
        <Row style={{ height: "100%"}}>
          <Col xs={12} className='boarder' style={{ height: "100%", overflow: "auto", paddingLeft: 0, paddingRight: 0 }}>
            <Paper elevation={0} sx={{ height: "100%", display: "flex", flexFlow: "column" }}>
              <DataTable
                data={data["data"]} count={data["count"]} ref={tableRef} loadData={loadData}
                columns={{
                  "datetime": { "label": t("general_dateTime"), "sorting": false},
                  "device": { "label": t("alert_device"), "sorting": false},
                  "event": { "label": t("alert_event"), "sorting": false}
                }}
              />
            </Paper>
          </Col>
        </Row>
      </Container>

      <LoadingScreen isOpen={loading} text={t("general_loading")}/>
    </div>
  </>)
}

export default Alert
