import React, { useEffect, useState } from 'react'
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Box, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListItemAvatar, Avatar, Menu, Stack, Typography } from '@mui/material';
import { Button, Container, Form } from 'react-bootstrap'
import moment from 'moment';
import 'moment/locale/zh-hk'

import { FaAngleDoubleRight } from "react-icons/fa";
import { GoAlertFill } from "react-icons/go";
import { MdWatch, MdLanguage, MdOutlineLockReset } from "react-icons/md";
import { RiLiveFill } from "react-icons/ri";
import { IoPlayBack, IoSettingsSharp, IoRainy } from "react-icons/io5";
import { FaTemperatureFull } from "react-icons/fa6";
import { IoIosLogOut, IoIosWater } from "react-icons/io";
import { PiList } from "react-icons/pi";

import Dashboard from 'Pages/Dashboard/Dashboard';

import PopupModal from 'Shared/Components/PopupModal';
import useWindowSize from 'Shared/Hooks/useWindowSize'
import useFetchData from 'Shared/Hooks/useFetchData';
import useLoop from 'Shared/Hooks/useLoop';
import LoadingScreen from 'Shared/Components/LoadingScreen';
import { IconButtonComponent } from 'Shared/Components/Icon';
import { showSuccess } from 'Shared/Components/NotifyToast';
import { apiUrl, webUrl } from 'Shared/utils';

const drawerWidth = { "xs": 60, "lg": 240 }
const appbarHeight = 60

const MenuTab = ({ open, icon, route, label }) => {
  const navigate = useNavigate()

  const handleClick = () => {
    navigate(`./${route}`)
  }

  return (<>
    {(open === true) ? (
      <ListItem disablePadding>
        <ListItemButton onClick={handleClick}>
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText primary={label}/>
        </ListItemButton>
      </ListItem>
    ) : (<>
      <ListItem disablePadding sx={{ display: { xs: 'none', lg: 'block' } }}>
        <ListItemButton onClick={handleClick}>
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText primary={label}/>
        </ListItemButton>
      </ListItem>

      <ListItem disablePadding sx={{ display: { xs: 'flex', lg: 'none' }, justifyContent: "center" }}>
        <IconButtonComponent title={label} placement="right" onClick={handleClick}>
          {icon}
        </IconButtonComponent>
      </ListItem>
    </>)}
  </>)
}

const Main = ({ logoutCallback }) => {
  const windowSize = useWindowSize()
  const routeParam = useParams()
  const navigate = useNavigate()
  const { send } = useFetchData()
  const { t, i18n } = useTranslation()

  const [projects, setProjects] = useState()
  const [projectName, setProjectName] = useState()
  const [profile, setProfile] = useState()

  const [anchorEl, setAnchorEl] = useState(null);
  const [projectModelOpen, setProjectModelOpen] = useState(false)
  const [languageModelOpen, setLanguageModelOpen] = useState(false)
  const [changePasswordModalOpen, setChangePasswordModalOpen] = useState(false)

  const [time, setTime] = useState()
  const [weatherInfo, setWeatherInfo] = useState()
  const [weatherWarning, setWeatherWarning] = useState()

  const [mobileOpen, setMobileOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  const [originalPassword, setOriginalPassword] = useState()
  const [newPassword, setNewPassword] = useState()

  const [loading, setLoading] = useState(true);

  const getProjects = async () => {
    const body = await send({
      url: `${apiUrl}/projects`,
      method: "GET",
      returnType: "json"
    })

    body && setProjects(body.map((i) => ({
      "project_id": i.value,
      "project_name": JSON.parse(i.description)
      // "modules": modules
    })))
  }

  const getProfile = async () => {
    const body = await send({
      url: `${apiUrl}/authentication/profile`,
      method: "GET",
      returnType: "json"
    })

    body && setProfile(body)
  } 

  const getTime = () => {
    setTime(moment(new Date()).locale(i18n.language.toLowerCase()).format("LLLL"))
  }

  const getWeature = async () => {
    await Promise.all([
      send({
        url: `${apiUrl}/weather/info`,
        method: "GET",
        returnType: "json"
      }).then(res =>
        res && setWeatherInfo(res)
      ),

      send({
        url: `${apiUrl}/weather/warning`,
        method: "GET",
        returnType: "json"
      }).then(res =>
        res && setWeatherWarning(res)
      )
    ]);
  }

  useEffect(() => {
    const init = async () => {
      await Promise.all([
        getProjects(),
        getProfile(),
        getTime(),
        getWeature()
      ]);

      setLoading(false)
    }

    init()
  }, [])

  useEffect(() => {
    if (projects && routeParam.project) {
      setProjectName(projects.filter((i) => i["project_id"] == routeParam.project)[0]["project_name"])
    }
  }, [projects, routeParam.project])

  const selectProject = (project_code) => {
    navigate(project_code)
    setProjectModelOpen(false)
  }

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang)
    setLanguageModelOpen(false)
  }

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  }
  
  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  }

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  }

  const onChangePassword = async (e) => {
    e.preventDefault()

    const body = await send({
      url: `${apiUrl}/authentication/change_password`,
      method: "POST",
      body: JSON.stringify({
        "original_password": originalPassword,
        "new_password": newPassword
      }),
      returnType: "json"
    })

    if (body != null) {
      showSuccess(t("changePassword_success"))
      setChangePasswordModalOpen(false)
    }
  }

  useLoop(getTime, 1000)
  useLoop(getWeature, 600000)

  return (<>
    <Box sx={{ display: 'flex', flexDirection: 'column'}}>
      <Box sx={{ height: appbarHeight, zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: "white", boxShadow: 1, display: "flex", alignItems: "center", paddingLeft: "10px", paddingRight: "10px", overflow: "hidden" }}>
        <Stack justifyContent="space-between" direction="row" spacing={5} style={{ "height": "100%", "width": "100%" }}>
          <div style={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Box sx={{ display: { xs: 'none', lg: 'flex' }, height: "100%", justifyContent: "center", alignItems: "center" }}>
              <img src={`${webUrl}/logo/logo.png`} style={{ height: "80%"}} />
            </Box>

            <Box sx={{ display: { xs: 'flex', lg: 'none' }, height: "100%", justifyContent: "center", alignItems: "center" }}>
              <img src={`${webUrl}/logo/favicon.png`} style={{ height: "80%"}} />
            </Box>
          </div>

          {projectName &&
            <div style={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
              <Typography sx={{ display: '-webkit-box', overflow: 'hidden', WebkitBoxOrient: 'vertical', WebkitLineClamp: 2 }} variant="body1">
                {projectName[i18n.language.toLowerCase().substring(0, 2)]}
              </Typography>
            </div>
          }

          <Stack justifyContent="space-between" direction="row" spacing={2} style={{ height: "100%", justifyContent: "center", alignItems: "center" }}>
            <Box sx={{ height: "100%", display: { xs: 'none', md: 'flex' }, flexDirection: 'column' , justifyContent: "center", alignItems: "center" }}>
              <div style={{ height: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                {time &&
                  <div style={{ textAlign: "center", whiteSpace: "nowrap" }}>{time}</div>
                }
              </div>

              <div style={{ height: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Stack justifyContent="space-between" direction="row" spacing={2} style={{ height: "100%", justifyContent: "center", alignItems: "center" }}>
                  {weatherInfo && <>
                    <img src={weatherInfo["icon"]} style={{ height: "20px"}} />
                      
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", whiteSpace: "nowrap" }}>
                      <FaTemperatureFull size={20} /> {weatherInfo["temperature"]}°C
                    </div>

                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", whiteSpace: "nowrap" }}>
                      <IoRainy size={20} /> {weatherInfo["rainfall"]}mm
                    </div>

                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", whiteSpace: "nowrap" }}>
                      <IoIosWater size={20} /> {weatherInfo["humidity"]}%
                    </div>
                  </>}

                  {weatherWarning && <>
                    {weatherWarning.map((i) => (
                      <img src={i} style={{ height: "20px"}} />
                    ))}
                  </>}
                </Stack>
              </div>
            </Box>

            <IconButtonComponent title={t("general_project")}  onClick={() => setProjectModelOpen(true)}>
              <PiList size={20}/>
            </IconButtonComponent>

            <IconButtonComponent title={t("general_settings")} onClick={(event) => setAnchorEl(event.currentTarget)}>
              <IoSettingsSharp size={20}/>
            </IconButtonComponent>
          </Stack>
        </Stack>
      </Box>

      {(routeParam.project != null && routeParam.project != "") ? (
        <Box sx={{ display: 'flex', flexDirection: 'row'}}>
          <Box sx={{ width: { xs: drawerWidth["xs"], lg: drawerWidth["lg"] }}} >
            <Drawer variant="permanent" open sx={{ '& .MuiDrawer-paper': { boxSizing: 'border-box', width: {xs: drawerWidth["xs"], lg: drawerWidth["lg"] } } }}>
              <Box sx={{ display: 'block' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column'}} style={{ height: windowSize["height"] }}>
                  <Box sx={{ height: appbarHeight }} />
                  <Stack justifyContent="space-between" direction="column" spacing={0} style={{ height: windowSize["height"]-appbarHeight }}>
                    <Box style={{ flexGrow: 1, overflow: "auto" }}>
                      <List  sx={{ padding: { xs: '5px', lg: '0px' } }} >
                        <MenuTab icon={<GoAlertFill size={25}/>} route="alert" label={t("route_alert")}/>
                        <MenuTab icon={<RiLiveFill size={25}/>} route="live" label={t("route_live")}/>
                        {/* <MenuTab icon={<IoPlayBack size={25}/>} route="playback" label={t("route_playback")}/> */}
                        <MenuTab icon={<MdWatch size={25}/>} route="smartwatch" label={t("route_smartwatch")}/>
                      </List>
                    </Box>

                    <Divider sx={{ bgcolor: "secondary.light" }}/>

                    <Box>
                      <ListItem disablePadding sx={{ display: { xs: 'flex', lg: 'none' }, paddingTop: "10px", paddingBottom: "10px", justifyContent: "center" }}>
                        <IconButtonComponent title={t("general_settings")} onClick={() => handleDrawerToggle()}>
                          <FaAngleDoubleRight size={20} />
                        </IconButtonComponent>
                      </ListItem>
                    </Box>
                  </Stack>
                </Box>
              </Box>
            </Drawer>

            <Drawer variant="temporary" open={mobileOpen} onTransitionEnd={handleDrawerTransitionEnd} onClose={handleDrawerClose} ModalProps={{ keepMounted: true }} sx={{ display: { xs: 'block', lg: 'none' }, '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth["lg"] } }}>
              <Box sx={{ display: 'block' }} >
                <Box sx={{ display: 'flex', flexDirection: 'column'}} style={{ height: windowSize["height"] }}>
                  <Box sx={{ height: appbarHeight }} />
                  <Stack justifyContent="space-between" direction="column" spacing={0} style={{ height: windowSize["height"]-appbarHeight }}>
                    <Box>      
                      <List style={{ padding: "0px" }} >
                        <MenuTab icon={<GoAlertFill size={25}/>} route="alert" label={t("route_alert")} open={true}/>
                        <MenuTab icon={<RiLiveFill size={25}/>} route="live" label={t("route_live")} open={true}/>
                        {/* <MenuTab icon={<IoPlayBack size={25}/>} route="playback" label={t("route_playback")} open={true}/> */}
                        <MenuTab icon={<MdWatch size={25}/>} route="smartwatch" label={t("route_smartwatch")} open={true}/>
                      </List>
                    </Box>
                  </Stack>
                </Box>
              </Box>
            </Drawer>
          </Box>

          <Box component="main" sx={{ width: { xs: windowSize["width"]-drawerWidth["xs"], lg: windowSize["width"]-drawerWidth["lg"] }, height: windowSize["height"]-appbarHeight }}>
            {!loading &&
              <Outlet />
            }
          </Box>
        </Box>
      ) : (
        <Dashboard />
      )}
    </Box>

    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} transformOrigin={{ horizontal: 'right' }} MenuListProps={{ sx: { py: 0 } }}>
      <List style={{ padding: "0px" }}>
        {profile && 
          <ListItem>
            <ListItemAvatar>
              <Avatar src={profile["picture"]} />
            </ListItemAvatar>
            <ListItemText primary={profile["name"]} secondary={profile["email"]}/>
          </ListItem>
        }

        <Divider sx={{ bgcolor: "secondary.light" }}/>

        <ListItem disablePadding>
          <ListItemButton onClick={() => { setLanguageModelOpen(true); setAnchorEl(null); }}>
            <ListItemIcon>
              <MdLanguage size={20} />
            </ListItemIcon>
            <ListItemText primary={t("general_language")}/>
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton onClick={() =>{ setChangePasswordModalOpen(true); setAnchorEl(null); }}>
            <ListItemIcon>
              <MdOutlineLockReset size={20}/>
            </ListItemIcon>
            <ListItemText primary={t("general_changePassword")}/>
          </ListItemButton>
        </ListItem>
        
        <ListItem disablePadding>
          <ListItemButton onClick={() =>{ logoutCallback(); setAnchorEl(null); }}>
            <ListItemIcon>
              <IoIosLogOut size={20}/>
            </ListItemIcon>
            <ListItemText primary={t("general_logout")}/>
          </ListItemButton>
        </ListItem>
      </List>
    </Menu>

    <PopupModal maxHeight="80%" width="80%" isOpen={projectModelOpen} toggle={() => setProjectModelOpen(false)}>
      <List style={{ padding: "0px" }}>
        {(projects ?? []).map((i) => 
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText primary={`${i["project_name"][i18n.language.toLowerCase().substring(0, 2)]}`} onClick={() => selectProject(`/${i["project_id"]}`)} />
            </ListItemButton>
          </ListItem>
        )}
      </List>
    </PopupModal>

    <PopupModal maxHeight="80%" width="80%" isOpen={languageModelOpen} toggle={() => setLanguageModelOpen(false)}>
      <List style={{ padding: "0px" }}>
        {(["EN", "ZH-TW"]).map((i) => 
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText primary={t(i)} onClick={() => changeLanguage(i)} />
            </ListItemButton>
          </ListItem>
        )}
      </List>
    </PopupModal>

    <PopupModal maxHeight="80%" width="80%" isOpen={changePasswordModalOpen} toggle={() => setChangePasswordModalOpen(false)}>
      <Form onSubmit={onChangePassword} style={{ width: "100%" }}>
        <Form.Group className="m-3">
          <Form.Label>{t("changePassword_currentPassword")}</Form.Label>
          <Form.Control type="password" placeholder={t("changePassword_currentPassword")} onChange={(e) => setOriginalPassword(e.target.value)} required/>
        </Form.Group>

        <Form.Group className="m-3">
          <Form.Label>{t("changePassword_newPassword")}</Form.Label>
          <Form.Control type="password" placeholder={t("changePassword_newPassword")} onChange={(e) => setNewPassword(e.target.value)} required/>
        </Form.Group>

        <Form.Group className="m-3">
          <Button type="submit" style={{ width: "100%" }}>
            {t("changePassword_submit")}
          </Button>
        </Form.Group>
      </Form>
    </PopupModal>

    <LoadingScreen isOpen={loading} text={t("general_inititalizing")}/>
  </>)
}

export default Main
