import { forwardRef, useCallback } from "react";
import { SnackbarContent, useSnackbar } from "notistack";
import { Avatar, ListItem, ListItemText, ListItemAvatar } from '@mui/material';
import { IoIosWarning, IoIosClose } from "react-icons/io";
import { IconButtonComponent } from "./Icon";

const WarningToast = forwardRef(({ id, ...props }, ref) => {
  const { closeSnackbar } = useSnackbar();

  const message = props.message.split("|")

  const handleDismiss = useCallback(() => {
    closeSnackbar(id);
  }, [id, closeSnackbar]);
  
  return (
    <SnackbarContent ref={ref}>
      <ListItem style={{ backgroundColor: "rgb(255, 117, 103)", minWidth: "344px" }} disablePadding>
        <ListItemAvatar  style={{ marginLeft: "15px" }}>
          <IoIosWarning style={{ color: "red" }} size={25}/>
        </ListItemAvatar>
        <ListItemText primary={message[0]} secondary={message[1]} />
        <IconButtonComponent onClick={handleDismiss} style={{ color: "red", marginRight: "10px" }}>
          <IoIosClose />
        </IconButtonComponent>
      </ListItem>
    </SnackbarContent>
  );
});

WarningToast.displayName = "WarningToast";

export default WarningToast;
