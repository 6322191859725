import { useParams } from 'react-router-dom';
import { showError } from 'Shared/Components/NotifyToast'

const useFetchData = () => {
  const routeParam = useParams()

  const send = async ({method, url, params, headers, body, returnType}) => {
    try {
      const res = await fetch(`${url}${params ? `?${new URLSearchParams(params)}` : ""}`, {
        method: method,
        headers: {
          "Content-Type": "application/json",
          'Authorization': localStorage.getItem('access_token'),
          'Project': routeParam.project
        },
        body: body
      })

      if (res.status === 200) {
        switch(returnType) {
          case "json":
            return await res.json()
          case "blob":
            return await res.blob()
          default:
            return res
        }
      }
      else {
        showError(await res.text())
        return null
      }
    }
    catch (error) {
      showError(`ERROR: ${error}`)
      return null
    }
  }

  return { send };
}

export default useFetchData;
