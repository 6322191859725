import React, { forwardRef, useState, useImperativeHandle, useRef } from 'react'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Pagination, Stack, CircularProgress, Box, TableSortLabel } from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { visuallyHidden } from '@mui/utils';

import { IconButtonComponent } from './Icon';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		fontSize: 14,
		fontWeight: 'bold'
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14,
	},
}));

const DataTable = forwardRef(({ columns, data, count, actions, loadData }, ref) => {
  const tableStateRef = useRef({
    "order": true,
    "orderBy": "",
    "page": 1
  })

	const [tableState, setTableState] = useState(tableStateRef.current)
	const [loading, setLoading] = useState(false)

  useImperativeHandle(ref, () => ({
		getState: () => {
			return tableStateRef.current
		},
    setLoading: (value) => {
			setLoading(value)
		},
		setPage: (page) => {
			tableStateRef.current.page = page
      setTableState(tableStateRef.current)
		}
	}))

	const sort = (id) => {
		if (tableStateRef.current.orderBy === id) {
      tableStateRef.current.order = !tableStateRef.current.order
		}
		else {
			tableStateRef.current.order = true
			tableStateRef.current.orderBy = id
		}

    setTableState(tableStateRef.current)

		loadData()
	}

  const handlePageChange = (_, newPage) =>{
		tableStateRef.current.page = newPage
		setTableState(tableStateRef.current)
		
		loadData()
	}

  return (<>
    {(loading || data == null) ? (
      <Box className="center">
        <CircularProgress />
      </Box>
    ) : (
      <TableContainer style={{ flexGrow: 1 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {Object.entries(columns).map((i) => <> 
                {i[1]["sorting"] ? (
                  <StyledTableCell sx={{ minWidth: i[1]["minWidth"] }} align="center" key={i[0]} sortDirection={tableState.orderBy === i[0] ? (tableState.order ? 'asc' : 'desc') : false}>
                    <TableSortLabel active={tableState.orderBy === i[0]} direction={tableState.order ? 'asc' : 'desc'} onClick={() => sort(i[0])}>
                      <div style={{ overflow: "hidden", whiteSpace: "nowrap"}}>{i[1]["label"]}</div>
                      
                      {tableState.orderBy === i[0] ? (
                        <Box component="span" sx={visuallyHidden}>
                          {tableState.order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </StyledTableCell>
                ) : (
                  <StyledTableCell sx={{ minWidth: i[1]["minWidth"] }} align="center">
                    <div style={{ overflow: "hidden", whiteSpace: "nowrap"}}>{i[1]["label"]}</div>      
                  </StyledTableCell>
                )}
              </>)}

              {actions && 
                <StyledTableCell align="center">{''}</StyledTableCell>
              }
            </TableRow>
          </TableHead>

          <TableBody>
            {data.map((row, i) => (<>	
              <TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                {Object.entries(columns).map((j) =>
                  <StyledTableCell align="center" key={j[0]}>
                    {j[1]["nowrap"] ? (
                      <div style={{ overflow: "hidden", whiteSpace: "nowrap"}}>{(row[j[0]] != "" && row[j[0]] != null) ? row[j[0]] : "-"}</div>
                    ) : (
                      <div>{(row[j[0]] != "" && row[j[0]] != null) ? row[j[0]] : "-"}</div>
                    )}
                  </StyledTableCell>
                )}

                {actions &&
                  <StyledTableCell align="center" sx={{width : `${actions.length*50}px`}} key="actionBtn">
                    <Stack justifyContent="center" direction="row" spacing={0} >
                      {actions.map((action) => 
                        <IconButtonComponent title={action.text} onClick={() => action.onClick(row)}>
                          {action.icon}
                        </IconButtonComponent>
                      )}
                    </Stack>
                  </StyledTableCell>
                } 
              </TableRow>
            </>))}
          </TableBody>
        </Table>
      </TableContainer>
    )}
    
    {(count != null && count > 0) && 
      <Pagination page={tableState["page"]} count={count} boundaryCount={2} onChange={handlePageChange}/>
    }
  </>)
})

export default DataTable
