import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Container, Col, Row } from 'react-bootstrap';
import { Paper, Stack } from '@mui/material';
import { MdCenterFocusStrong, MdOutlineSos, MdCrisisAlert, MdOutlineRoute } from 'react-icons/md';
import { FaTemperatureHigh, FaHeartbeat } from "react-icons/fa";

import { useSnackbar } from 'notistack';

import DataTable from 'Shared/Components/DataTable'
import useLoop from 'Shared/Hooks/useLoop';
import useFetchData from 'Shared/Hooks/useFetchData';
import LoadingScreen from 'Shared/Components/LoadingScreen';
import { IconComponent } from 'Shared/Components/Icon'
import { apiUrl } from 'Shared/utils';

import MapDisplay from './components/MapDisplay';

const Smartwatch = () => {
  const { send } = useFetchData()
  const { t, i18n } = useTranslation()
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate()

  const [data, setData] = useState({})

  const [loading, setLoading] = useState(true);

  const mapRef = useRef(null);
  const latest_alert = useRef({
    "value": ""
  });

  const loadData = async () => {
    const alertIcons = {
      "sos": {
        "text": "smartwatch_sos",
        "icon": <MdOutlineSos />
      },
      "danger_zone": {
        "text": "smartwatch_intrusion",
        "icon": <MdCrisisAlert />
      },
      "high_temperature": {
        "text": "smartwatch_highTemperature",
        "icon": <FaTemperatureHigh />
      },
      "abnormal_heart_rate": {
        "text": "smartwatch_abnormalHeartRate",
        "icon": <FaHeartbeat />
      }
    }

    const getAlertIcons = (data) => {
      const activeWarning = Object.keys(alertIcons).filter((i) => data[i] == true)

      if (activeWarning.length === 0) {
        return null
      }
      
      return (
        <Stack justifyContent="center" direction="row" spacing={2}>
          {activeWarning.map((i) =>
            <IconComponent title={t(alertIcons[i]["text"])}>
              {alertIcons[i]["icon"]}
             </IconComponent>
          )}
        </Stack>
      )
    }

    await Promise.all([
      send({
        url: `${apiUrl}/smartwatch/latest`,
        method: "GET",
        returnType: "json"
      }).then(res => {
        if (res != null) {
          mapRef.current.updateData(res)

          setData({
            "data": res.features.map((i) => ({
              "device_IMEI": i["properties"]["device_IMEI"],
              "worker_name": i["properties"]["worker_name"],
              "battery": i["properties"]["battery"],
              "signal": i["properties"]["signal"],
              "temperature": i["properties"]["body_temperature"],
              "heart_rate": i["properties"]["heartbeat"],
              "blood_pressure": (i["properties"]["shrink"] != null && i["properties"]["diastolic"] != null) ? `${i["properties"]["shrink"]} / ${i["properties"]["diastolic"]}` : null,
              "wearing": (i["properties"]["wearing"] && i["properties"]["online"]) ? t("general_yes") : t("general_no"),
              "alert_icons": getAlertIcons(i["properties"]),
              "last_update": i["properties"]["last_update"],
              "last_online": i["properties"]["last_online"],
              "sos": i["properties"]["sos"],
              "danger_zone": i["properties"]["danger_zone"],
              "high_temperature": i["properties"]["high_temperature"],
              "abnormal_heart_rate": i["properties"]["abnormal_heart_rate"]
            }))
          })
        }
      }),
      
      send({
        url: `${apiUrl}/smartwatch/danger_zone`,
        method: "GET",
        returnType: "json"
      }).then(res => {
        res && mapRef.current.updateZone(res)
      }),

      send({
        url: `${apiUrl}/smartwatch/get_latest_alert`,
        params: {
          id: latest_alert.current["value"]
        },
        method: "GET",
        returnType: "json"
      }).then(res => {
        if (!res) { return }

        if (res["index"]) {
          latest_alert.current["value"] = res["index"]
        }
        else if (res["data"].length > 0) {
          latest_alert.current["value"] = res["data"].length && res["data"][0]["record_id"]

          res["data"].forEach((i) => {
            enqueueSnackbar(`${i["event_name"]}|${i["device_name"]}`, { variant: 'warningToast' });
          })
        }
      })
    ]);
  }

  useEffect(() => {
    const init = async () => {
      await loadData()

      setLoading(false)
    }

    if (!loading) {
      mapRef.current.closePopup()
      setLoading(true)
    }

    init()
  }, [i18n.language])

  const focus = (id, zoom) => {
    const feature = mapRef.current.getFeature(id)

    const content = `
      <div style="font-size: 0.8em">
        ${t("smartwatch_deviceIMEI")}: ${feature.get("device_IMEI") ? feature.get("device_IMEI") : "-"}<br />
        ${t("smartwatch_workerName")}: ${feature.get("worker_name") ? feature.get("worker_name") : "-"}<br />
        ${t("smartwatch_battery")}: ${feature.get("battery") ? feature.get("battery") : "-"}<br />
        ${t("smartwatch_signal")}: ${feature.get("signal") ? feature.get("signal") : "-"}<br />
        ${t("smartwatch_temperature")}: ${feature.get("body_temperature") ? feature.get("body_temperature") : "-"}<br />
        ${t("smartwatch_heartRate")}: ${feature.get("heartbeat") ? feature.get("heartbeat") : "-"}<br />
        ${t("smartwatch_bloodPressure")}: ${(feature.get("shrink") != null && feature.get("diastolic") != null) ? `${feature.get("shrink")} / ${feature.get("diastolic")}` : "-"}<br />
        ${t("general_lastOnline")}: ${feature.get("last_online")}<br />
        ${t("general_lastUpdate")}: ${feature.get("last_update")}
      </div>
    `

    mapRef.current.zoomToFeature(id, content, zoom)
  }

  useLoop(loadData, 10000)

  return (<>
    <div className="mainContainer" style={{ height: "100%", padding: "10px" }}>
      <Container fluid className="mainContainer">
        <Row style={{"height": "50%", paddingBottom: "5px"}}>
          <Col xs={12} style={{"height": "100%", "paddingLeft": 0, "paddingRight": 0}}>
            <MapDisplay focus={focus} ref={mapRef}/>
          </Col>
        </Row>

        <Row style={{"height": "50%", paddingTop: "5px"}}>
          <Col xs={12} className='boarder' style={{height: "100%", overflow: "auto", paddingLeft: 0, paddingRight: "10px"}}>
            <Paper elevation={0} sx={{ height: "100%", display: "flex", flexFlow: "column" }}>
              <DataTable
                data={data["data"]} count={null}
                columns={{
                  "device_IMEI": { "label": t("smartwatch_deviceIMEI"), "sorting": false, "nowrap": true },
                  "worker_name": { "label": t("smartwatch_workerName"), "sorting": false, "nowrap": true },
                  "battery": { "label": t("smartwatch_battery"), "sorting": false, "nowrap": true },
                  "signal": { "label": t("smartwatch_signal"), "sorting": false, "nowrap": true },
                  "temperature": { "label": t("smartwatch_temperature"), "sorting": false, "nowrap": true },
                  "heart_rate": { "label": t("smartwatch_heartRate"), "sorting": false, "nowrap": true },
                  "blood_pressure": { "label": t("smartwatch_bloodPressure"), "sorting": false, "nowrap": true },
                  "wearing": { "label": t("smartwatch_wearing"), "sorting": false, "nowrap": true },
                  "alert_icons": { "label": t("smartwatch_warning"), "sorting": false, "nowrap": true },
                  "last_online": { "label": t("general_lastOnline"), "sorting": false, "nowrap": true },
                  "last_update": { "label": t("general_lastUpdate"), "sorting": false, "nowrap": true },
                }}
                actions={[
                  {icon: <MdCenterFocusStrong/>, text: "Locate Worker", onClick: function(row) { focus(row["device_IMEI"], true) }},
                  {icon: <MdOutlineRoute/>, text: "Record", onClick: function(row) { navigate(row["device_IMEI"]) }}
                ]}
              />
            </Paper>
          </Col>
        </Row>
      </Container> 
    </div>

    <LoadingScreen isOpen={loading} text={t("general_loading")}/>
  </>);
}

export default Smartwatch
