import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Button, Container, Form } from 'react-bootstrap'
import { Box, Paper } from '@mui/material';

import useWindowSize from 'Shared/Hooks/useWindowSize'
import useFetchData from 'Shared/Hooks/useFetchData';
import LoadingScreen from 'Shared/Components/LoadingScreen';
import { NotifyToast } from 'Shared/Components/NotifyToast';
import { apiUrl, webUrl } from 'Shared/utils';

const Login = ({ loginCallback }) => {
  const windowSize = useWindowSize()
  const { send } = useFetchData()
  const { t } = useTranslation()

  const [username, setUsername] = useState()
  const [password, setPassword] = useState()

  const [loading, setLoading] = useState(false);

  const login = async (e) => {
    e.preventDefault()

    setLoading(true)
    
    const body = await send({
      url: `${apiUrl}/authentication/login`,
      method: "POST",
      body: JSON.stringify({
        "username": username,
        "password": password
      }),
      returnType: "json"
    })

    if (body == null) {
      setLoading(false)
      return
    }

    localStorage.setItem("access_token", body["access_token"])
    localStorage.setItem("refresh_token", body["refresh_token"])

    loginCallback(body["expires_in"])
    setLoading(false)
  }

  return (<>
    <img src={`${webUrl}/assets/background.png`} style={{ width: windowSize["width"], height: windowSize["height"], position: "absolute", objectFit: "fill", zIndex: -1 }} />
    <Container fluid className='mainContainer' style={{ width: windowSize["width"], height: windowSize["height"], padding: "10px" }}>
      <Paper elevation={3} style={{ width: "70%", display: "flex", flexDirection: "column", alignItems: "center", paddingTop: "25px", paddingBottom: "25px", paddingLeft: "10px", paddingRight: "10px", maxHeight: "90%", margin: "auto", overflow: "auto" }}>
        <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
          <img src={`${webUrl}/logo/logo.png`} style={{ height: "50px" }} />
        </Box>

        <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
          <img src={`${webUrl}/logo/favicon.png`} style={{ height: "50px" }} />
        </Box>

        <Form onSubmit={login} style={{ width: "100%" }}>
          <Form.Group className="m-3">
            <Form.Label>{t("login_username")}</Form.Label>
            <Form.Control type="text" placeholder={t("login_username")} onChange={(e) => setUsername(e.target.value)} required/>
          </Form.Group>

          <Form.Group className="m-3">
            <Form.Label>{t("login_password")}</Form.Label>
            <Form.Control type="password" placeholder={t("login_password")} onChange={(e) => setPassword(e.target.value)} required/>
          </Form.Group>

          <Form.Group className="m-3"></Form.Group>

          <Form.Group className="m-3 mt-5">
            <Button type="submit" style={{ width: "100%" }}>
              {t("login_submit")}
            </Button>

            <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
              <Button variant="link" style={{ margin: "auto" }}>{t("login_forgetPassword")}</Button>
            </div>
          </Form.Group>
        </Form>
      </Paper>
    </Container>

    <div>{<NotifyToast />}</div>
    
    <LoadingScreen isOpen={loading} text={t("general_processing")}/>
  </>)
}

export default Login
